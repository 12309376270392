.btn-icon {
  margin: auto;
  background-color: white;
  height: 4px;
  width: 40px;
}

.toggle-btn {
  width: 60px;
  height: 40px;
  float: right;
  display: flex;
  position: relative;
  cursor: pointer;
}

.btn-icon {
  transition-duration: 200ms;
  width: 40px;
  height: 4px;
  margin: auto;

  background-color: #ffff;
}

.btn-icon::before {
  transition-duration: 100ms;
  content: "";
  width: 40px;
  height: 4px;
  top: 5px;
  position: absolute;
  background-color: #fff;
}
.btn-icon::after {
  transition-duration: 200ms;
  content: "";
  width: 40px;
  height: 4px;
  bottom: 5px;
  position: absolute;
  background-color: #ffff;
}

.toggle-btn.open .btn-icon {
  transition-duration: 200ms;
  background-color: transparent;
}

.toggle-btn.open .btn-icon::before {
  transform: rotateZ(45deg) scaleX(1.2) translate(13px, 13px);
}

.toggle-btn.open .btn-icon::after {
  transform: rotateZ(-45deg) scaleX(1.2) translate(12px, -12px);
}

.nav-list a{
    position: relative;
    z-index: 10;
}

.nav-list a:before {
  content: "";
  transition: 300ms;
  top: 2rem;
  left: 1px;
  height: 5px;
position: absolute;
  background-color: #8733f7;
  border-radius: 10px;
  width: 0%;

  /* bottom: 10px; */
  z-index: -1;
}

.nav-list a:hover::before {
    width: 100%;
}
